import React, {useEffect, useRef} from 'react';
import WebMercatorViewport from 'viewport-mercator-project'
import {Item} from './App';
import {ViewState} from 'react-map-gl';
import {ScalePower} from 'd3-scale';
import Flatbush from 'flatbush';
import styled from '@emotion/styled';

interface Props {
  countsData: Item[] | undefined;
  viewport: ViewState;
  sizeScale: ScalePower<number, number, never> | undefined;
}

const Svg = styled.svg(`
  position: absolute;
  left:0;top:0;
  width: 100%;height:100%;
`);
const LabelG = styled.g(`
  opacity: 0;
`);

const LabelText = styled.text(`
  transition: opacity 0.5s;
  text-anchor: middle;
  alignment-baseline: middle;
  font-size:  10px;
  paint-order: stroke;
  fill: #fff;
  stroke-width: 1px;
  stroke-linecap: butt;
  stroke-linejoin: miter;
  // stroke: #000;
  // font-weight: normal;
  stroke: rgb(170, 20, 24);
  font-weight: bold;
`);

const pad = 10;

const LabelsOverlay: React.FC<Props> = (props) => {
  const {countsData, viewport, sizeScale} = props;
  const ref = useRef<SVGGElement>(null);
  useEffect(() => {
    if (ref.current && countsData) {
      const index = new Flatbush(countsData.length);
      const textNodes = ref.current.querySelectorAll('.label');
      for (let i = 0; i < textNodes.length; i++) {
        const {left, top, right, bottom} = textNodes[i].getBoundingClientRect();
        index.add(left, top, right, bottom);
      }
      index.finish();

      const toOmit = new Set<number>();
      // assuming they are sorted by size
      for (let i = textNodes.length - 1; i >= 0 ; i--) {
        if (toOmit.has(i)) continue;
        const textNode = textNodes[i];
        const {left, top, right, bottom} = textNode.getBoundingClientRect();
        const indices = index.search(left - pad, top - pad, right + pad, bottom + pad);
        for (const idx of indices) {
          if (idx !== i) {
            toOmit.add(idx);
          }
        }
      }
      for (let i = 0; i < textNodes.length; i++) {
        // @ts-ignore
        textNodes[i].style.opacity = toOmit.has(i) ? '0' : '1';
      }
    }
  }, [viewport.zoom, ref.current, countsData])

  if (!countsData || !sizeScale) return null;

  const mercator = new WebMercatorViewport(viewport);
  // @ts-ignore
  const {width,height} = viewport;
  return (
    <Svg width={width} height={height}>
    <g ref={ref}>
      {countsData
      .map((d, i) => {
        const [cx, cy] = mercator.project([d.lon, d.lat]);
        const r = sizeScale(d.detained);
        const scale = Math.max(1,Math.min(1.5,Math.pow(viewport.zoom,3)*r/300));
        return (
          <LabelG
            key={d.name}
            className="label"
            transform={`translate(${cx},${cy + (r < 10 ? r * 1.2 + scale*5: 0)})scale(${scale})`}
          >
            <LabelText>
              {d.name}
            </LabelText>
            <LabelText
              y={10}
            >
              {d.detained}
            </LabelText>
          </LabelG>
        );
      }).reverse()}
    </g>
    </Svg>
  );
};

export default LabelsOverlay;